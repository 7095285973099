import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { graphql } from "gatsby";
import PageLayout from "./../../../templates/product";
export const PageQuery_product_nl_scannermdx = graphql`
        query PageQuery_product_nl_scannermdx {
            # Required by gatsby-plugin-react-i18next
            locales: allLocale(filter: {language: {eq: "nl"}, ns: {in: ["common", "product"]}}) {
                ...TranslationFragment
            }
            # Required by gatsby-plugin-image
            mdx(frontmatter:
                {
                    path: {regex: "/scanner/"},
                    language: {eq: "nl"}
                }) {
                frontmatter {
                    ...MdxServiceFrontmatterFragment
                }
            }
        }
    `;
export const _frontmatter = {
  "title": "Herkenning",
  "description": "Bonnen en facturen scannen",
  "path": "/product/scanner",
  "date": "2022-06-11T00:00:00.000Z",
  "template": "Product",
  "language": "nl",
  "icon": "bx-barcode-reader",
  "prio": 1,
  "stage": "production",
  "hero_image": "./images/scanner/main-promotional.png",
  "tags": ["product", "scanner", "receipt", "invoice", "ai", "ocr"],
  "main": {
    "title": "Extraheer Details + Structuur",
    "description": "Herken automatisch meer gegevens uit bonnen en facturen met nieuwe OCR extractietechnologie."
  },
  "comparison": {
    "description": "Versleep de schuifregelaar voor verschillen - huidige aanbod (l) tegenover Cheesebyte OCR scanner (r).",
    "imageBefore": "./images/scanner/invoice-before.png",
    "imageAfter": "./images/scanner/invoice-after.png"
  },
  "feature": {
    "title": "Bespaar Tijd En Geld",
    "description": "U hoeft zich niet langer zorgen te maken over ontbrekende informatie en de tijd die het kost om handmatig tekst te bewerken uit ongestructureerd bronmateriaal."
  },
  "usp": [{
    "title": "Kunstmatige Intelligentie",
    "description": "De nieuwste ontwikkelingen zorgen voor snelle beschikbaarheid van details.",
    "icon": "bx-cog"
  }, {
    "title": "Beveiliging",
    "description": "Versleuteling houdt uw gegevens veilig, afbeeldingen worden gewist na verwerking.",
    "icon": "bx-check-shield"
  }, {
    "title": "Automatische Detectie",
    "description": "Extraheer gestructureerde informatie voor productregels, BTW en betalingsinfo.",
    "icon": "bx-message-alt-detail"
  }, {
    "title": "Uitstekende Ondersteuning",
    "description": "Ondersteuning via e-mail, chat of telefoon (afhankelijk van gekozen plan) beschikbaar.",
    "icon": "bx-user-voice"
  }, {
    "title": "Effectief Scannen",
    "description": "Ga verder dan de 40 tot 80% van andere aanbieders en extraheer meer structuren.",
    "icon": "bx-barcode-reader"
  }, {
    "title": "Beeldkwaliteit",
    "description": "Zolang de ingevoerde afbeelding door een mens te lezen is, kan deze service het ook.",
    "icon": "bx-image-alt"
  }],
  "faq": [{
    "title": "Waarom zou ik van deze dienst gebruik maken?",
    "body": "Omdat handmatige stappen tijdens de gegevensverwerking kunnen worden overgeslagen via deze dienst. Het automatiseert de stappen richting het verkrijgen van structurele gegevens uit ongestructureerd bronmateriaal en maakt het proces \"plug and play\"."
  }, {
    "title": "Hoe verhoudt het zich tot bestaande software?",
    "body": "Concurrerende diensten baseren zich meestal op het vinden van betekenis in een grote soep van tekens door gebruik te maken van reguliere expressies en door (tegen meerprijs) extra oplossingen aan te bieden voor een betere analyse."
  }, {
    "title": "Wordt mijn land of taal ondersteund?",
    "body": "In eerste instantie worden Engels en Nederlands het best ondersteund. Aan gegarandeerde ondersteuning voor andere talen zal worden gewerkt volgens prioriteiten die voortkomen uit de betaalde plannen."
  }, {
    "title": "Hoe worden mijn gegevens veilig gebruikt?",
    "body": "Ingevoerde afbeeldingen worden beveiligd met AES-256 versleuteling en opgestuurd via een beveiligde verbinding. Op de server worden de ingevoerde gegevens direct verwijderd na de verwerking ervan. Gevoelige gegevens worden onafhankelijk verwerkt voor extra veiligheid."
  }],
  "plans": [{
    "id": "0dcd9975-daa0-4fd7-9eb7-7cb70b882419",
    "name": "Basis",
    "feature": [{
      "active": true,
      "description": "Privéprojecten en beperkt gebruik"
    }, {
      "active": false,
      "description": "Gelimiteerd tot 1 scan per minuut"
    }, {
      "active": false,
      "description": "Lagere prioriteit in wachtrij"
    }, {
      "active": false,
      "description": "Ondersteuning via e-mail"
    }]
  }, {
    "id": "f7fe4386-f87e-4efe-9a33-a7a05c0b1e54",
    "name": "Geavanceerd",
    "feature": [{
      "active": true,
      "description": "Voor kleinere ondernemingen"
    }, {
      "active": true,
      "description": "Hogere prioriteit in wachtrij"
    }, {
      "active": true,
      "description": "Nauwkeurig tot 99% (EN + NL)"
    }, {
      "active": false,
      "description": "Ondersteuning via e-mail en chat"
    }]
  }, {
    "id": "cdbe1afe-f17a-4f30-8d5d-466cbc154227",
    "name": "Expert",
    "feature": [{
      "active": true,
      "description": "Voor grotere ondernemingen"
    }, {
      "active": true,
      "description": "Met extra metadata per scan"
    }, {
      "active": true,
      "description": "Uitgebreide beheerfuncties"
    }, {
      "active": true,
      "description": "Steun via e-mail, chat & telefoon"
    }]
  }]
};
const layoutProps = {
  PageQuery_product_nl_scannermdx,
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      